import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Stack, ToggleButton } from '@mui/material';
import {
  AlertBar, AlertNotification, ArticleListItem, AutofillDropdown, Button, Card,
  DeletableChipsList, ContentAccordion, ContentCard, CtaCard, ActionBar, InputRadioGroup,
  SuccessChipsList, InputCheckbox, InputSwitch, TextInput, InputPassword, HalfColumnContent,
  BasicPagination, BulletPagination, ArrowPagination, JobListItem, MenuDropdown, BasicModal,
  NewToForward, Snackbar, CollectionFilters, InputFieldWithChips, ToggleButtons, FwrdTooltip,
  FwrdSlider, ContactModal, FwrdDatePicker, UploadExample
} from '../components';
import { IconPlus, IconLinkArrow, IconRightArrow, IconBank } from '../components/icons';
import '../scss/components.scss';
import defaultPhoto from '../../src/images/default-image-icon.jpg';

function AccordionSection () {
  return (
    <ContentAccordion
      id="test-accordion"
      heading={<h5>Label</h5>}
    >
      <p>
        Placeholder text cookies ensure that our website performs as expected to enhance your
        user experience on this website. You can learn more in our Privacy Policy.
      </p>
    </ContentAccordion>
  );
}

function SnackbarSection () {
  const [ snackbarState, setSnackbarState ] = useState(false);

  const handleClose = () => setSnackbarState(false);
  const handleClick = () => setSnackbarState(true);

  return (
    <section>
      <h1 className="header headline --large">Snackbar</h1>
      <Snackbar
        open={ snackbarState }
        title='Deleted'
        onClose={handleClose}
      >
        This task was deleted successfully
      </Snackbar>
      <Button onClick={handleClick}>Open Snackbar</Button>
    </section>
  );
}

function AlertBarSection () {
  function handleClearStorage () {
    sessionStorage.removeItem('dismissedAlertsList');
    window.location.reload();
  }

  return (
    <section className="--fullwidth">
      <h1 className='header headline --large'>Alert bars and notifications</h1>
      <AlertBar
        alertId="alert-bar-0"
        alertTitle="Weather Warning Alert"
      >
        This is an error alert — <strong>check it out!</strong>
      </AlertBar>
      <AlertNotification
        alertId="alert-note-1"
        alertTitle="Covid-19 Response"
        actionText="Read about our pandemic response"
        ctaText="Button"
        ctaLink="/"
      >
        Due to the COVID-19 pandemic, all drivers, vendors and visitors are required to complete a
        short questionnaire prior to entering any Forward Air facility. Click here to access the
        questionnaire online, or text CHECKIN to 72345. Paper questionnaires are also available at
        each facility.
      </AlertNotification>
      <Button onClick={ handleClearStorage }>Reset and reload</Button>
    </section>
  );
}

function ContentCardSection () {
  return (
    <>
      <div className="content-card-container">
        <ContentCard
          featured
          image={ defaultPhoto }
          category="Category"
          headline="Headline Sample With Multiple Lines"
          bodyCopy="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients."
          readTime="7 minute read"
          dateStamp="June 18, 2021"
          buttonRow={
            <>
              <Button className="card-button" variant="contained">Button</Button>
              <Button className="card-button" variant="text">Button</Button>
            </>
          }
        >
          <ActionBar
            dateStamp="2022-11-20"
            readTime="7 minute read"
          />
        </ContentCard>

        <ContentCard
          featured
          image={ defaultPhoto }
          category="Category"
          headline="Headline Sample With Multiple Lines"
          bodyCopy="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients."
          readTime="7 minute read"
          dateStamp="June 18, 2021"
          buttonRow={
            <Stack spacing={2} direction="row">
              <Button className="card-button" variant="contained">Button</Button>
              <Button className="card-button" variant="text">Button</Button>
            </Stack>
          }
        >
          <ActionBar
            dateStamp="2022-11-20"
          />
        </ContentCard>

        <ContentCard
          featured
          image={ defaultPhoto }
          category="Category"
          headline="Headline Sample With Multiple Lines"
          subheadline="Shane Williams"
          dateStamp="June 18, 2021"
        >
        </ContentCard>

        <ContentCard
          featured
          image={ defaultPhoto }
          category="Category"
          headline="Headline Sample With Multiple Lines"
          bodyCopy="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients."
          buttonRow={
            <>
              <Button className="card-button no-padding" variant="text" startIcon={ <IconLinkArrow color="#c8102e" /> }>Read More</Button>
            </>
          }
          className="no-padding"
        >
        </ContentCard>

        <ContentCard
          featured
          category="Category"
          headline="Headline Sample With Multiple Lines"
          dateStamp="2022-11-20"
          bodyCopy="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients."
          buttonRow={
            <Stack spacing={2} direction="row">
              <Button className="card-button" variant="contained">Button</Button>
              <Button className="card-button" variant="text">Button</Button>
            </Stack>
          }
        >
          <ActionBar
            dateStamp="2022-11-20"
            readTime="7 minute read"
          />
        </ContentCard>

        <ContentCard
          category="Category"
          headline="Headline Sample With Multiple Lines"
        >
          <ActionBar
            dateStamp="2022-11-20"
          />
        </ContentCard>

        <ContentCard
          featured
          category="Category"
          headline="Headline Sample With Multiple Lines"
          dateStamp="2022-11-20"
          bodyCopy="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients."
          buttonRow={
            <>
              <Button className="card-button no-padding" variant="text" startIcon={ <IconLinkArrow color="#c8102e" /> }>Read More</Button>
            </>
          }
          className="no-padding"
        />

        <CtaCard title="Drivers" ctaTitle="Learn More" ctaTarget="/" ctaImage={ defaultPhoto } overlay />

        <ContentCard
          featured
          category="Category"
          headline="Headline Sample With Multiple Lines"
          dateStamp="2022-11-20"
        />
      </div>
    </>
  );
}

function ModalSection () {
  const [ modalActive, setModalActive ] = useState(false);

  const handleOpenModal = () => setModalActive(true);
  const handleOpenCriticalModal = () => setModalActive('critical');
  const handleOpenFormModal = () => setModalActive('form');

  const handleClose = () => {
    setTimeout(setModalActive(false), 500);
  };

  let theModal = null;

  if (modalActive) {
    theModal = (
      <BasicModal
        heading='Credit Application'
        subheading='Fill out the information below to get started'
        primaryButton={<Button className='red-btn'>Button</Button>}
        secondaryButton={<Button className='wht-btn'>Button</Button>}
        onClose={handleClose}
        isCrit={modalActive === 'critical'}
        isForm={modalActive === 'form'}
      >
        <p>
          Modals are a variant of dialog used to present critical information or request user input
          needed to complete a user’s workflow. Modals interrupt a user’s workflow by design
        </p>
      </BasicModal>
    );
  }

  return (
    <section>
      <h1 className="header headline --large">Modal</h1>
      <Button onClick={handleOpenModal} >Activate Modal</Button>
      <Button onClick={handleOpenCriticalModal} >Activate Critical Modal</Button>
      <Button onClick={handleOpenFormModal} >Activate Form Modal</Button>
      {theModal}
    </section>
  );
}

function NewToForwardSection () {
  function handleClearStorage () {
    window.location.reload();
  }

  return (
    <section>
      <h1 className="header headline --large flex-row">New To Forward</h1>
      <NewToForward
        ntfId='ntfComp'
      />
      <Button onClick={ handleClearStorage }>Reset and reload</Button>
    </section>
  );
}

function SliderSection () {
  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: 100,
      label: '100'
    }
  ];
  return (
    <section>
      <h1 className="header headline --large flex-row">Slider</h1>
      <FwrdSlider
        marks={marks}
        min={0}
        max={100}
        valueLabelDisplay='auto'/>
    </section>
  );
}

function InputFieldWithChipSection () {
  function handleSelectedTags (items) {
  }

  return (
    <section>
      <h1 className="header headline --large flex-row">Input Field with Chips</h1>
      <InputFieldWithChips
        selectedTags={handleSelectedTags}
        fullWidth
        id="tags"
        name="tags"
      />
    </section>

  );
}

function ContactModalSection () {
  const [ modalActive, setModalActive ] = useState();

  const handleOpenContactModal = () => setModalActive(true);

  const handleClose = () => {
    setTimeout(setModalActive(false), 500);
  };

  let contactModal = null;

  if (modalActive) {
    contactModal = (
      <ContactModal
        onClose={handleClose}/>
    );
  }

  return (
    <section>
      <h1 className='header headline --large'>Contact Modal</h1>
      <Button onClick={handleOpenContactModal} >Open Contact Form Modal</Button>
      {contactModal}
    </section>
  );
}

export default function ComponentsPage () {
  const radioGroupOptions = [
    {
      label: 'Normal',
      value: 'radio-normal',
      checked: false
    },
    {
      label: 'Checked',
      value: 'radio-checked',
      checked: true
    },
    {
      label: 'Disabled',
      value: 'radio-disabled',
      disabled: true
    }
  ];

  const menuListItems = [
    {
      handleClick: () => {},
      text: 'Body 2',
      assistiveText: 'Assistive Text Line'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    },
    {
      handleClick: () => {},
      text: 'Body 2'
    }
  ];

  const deletableChipsItems = [
    { label: 'Deletable Chip' },
    { label: 'Delete Me' },
    { label: 'Click Me' }
  ];

  const clickableChipsItems = [
    { label: 'Inactive chip', clicked: false },
    { label: 'Active chip', clicked: true }
  ];

  //eslint-disable-next-line no-console
  const handleEvent = (...args) => console.dir(args);

  return (
    <>
      <Helmet>
        <title>Component Library</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <header className="components container">
        <h1 className="header headline --xlarge">Component Library</h1>
      </header>

      <div className="components container">
        <section>
          <h1 className="header headline --large">Checkbox</h1>
          <InputCheckbox
            label="Normal"
            name="checkbox-normal"
            checked={false}
          />
          <InputCheckbox
            label="Checked"
            name="checkbox-checked"
            checked={true}
          />
          <InputCheckbox
            label="Indeterminate"
            name="checkbox-indeterminate"
            indeterminate={true}
          />
          <InputCheckbox
            label="Disabled"
            name="checkbox-disabled"
            disabled
          />
          <InputCheckbox
            label="Disabled Checked"
            name="checkbox-disabled-checked"
            disabled
            checked
          />
        </section>

        <section>
          <h1 className="header headline --large">Radio</h1>
          <InputRadioGroup
            options={radioGroupOptions}
            name="radios"
          />
        </section>

        <section>
          <h1 className="header headline --large">Switch</h1>
          <InputSwitch
            label="Off"
            name="switch-off"
            checked={false}
          />
          <InputSwitch
            label="On"
            name="switch-on"
            checked={true}
          />
          <InputSwitch
            label="Disabled Off"
            name="switch-disabled-off"
            disabled
            checked={false}
          />
          <InputSwitch
            label="Disabled On"
            name="switch-disabled-on"
            disabled
            checked={true}
          />
        </section>

        <section>
          <h1 className="header headline --large">Text field</h1>
          <TextInput
            id="text-field-normal"
            label="Normal"
            value=""
          />
          <TextInput
            id="text-field-with-value"
            label="Normal with value"
            value="input data"
          />
          <TextInput
            id="text-field-with-helper"
            label="With helper text"
            helperText="Assistive, supportive, explanation text line."
            value=""
          />
          <TextInput
            id="text-field-error"
            label="Error state"
            value="bad input"
            error
          />
          <TextInput
            id="text-field-error-details"
            label="Error state with details"
            value="bad input"
            error
            helperText="Error Details"
          />
          <TextInput
            id="text-field-disabled"
            label="Disabled"
            disabled
          />
          <InputPassword />
        </section>

        <section>
          <h1 className="header headline --large">Button</h1>
          <div className="button-group">
            <Button variant="contained">Base</Button>
            <Button
              variant="contained"
              startIcon={ <IconPlus /> }
            >
              Leading Icon
            </Button>
            <Button variant="outlined">Outlined</Button>
            <Button
              variant="text"
              endIcon={ <IconRightArrow /> }
            >
              Trailing Icon
            </Button>
            <Button
              variant="text"
              startIcon={ <IconPlus /> }
              className="no-padding"
            >
              No Padding
            </Button>
          </div>
        </section>

        <section>
          <h1 className="header headline --large">Toggle Buttons</h1>
          <ToggleButtons>
            <ToggleButton
              value='imperial'
            >Imperial</ToggleButton>
            <ToggleButton
              value='Metric'
            >Metric</ToggleButton>
          </ToggleButtons>

          <ToggleButtons className='red-options' >
            <ToggleButton
              value='imperial'
            >Imperial</ToggleButton>
            <ToggleButton
              value='Metric'
            >Metric</ToggleButton>
          </ToggleButtons>

          <ToggleButtons>
            <ToggleButton
              value='imperial'
            >Imperial</ToggleButton>
            <ToggleButton
              value='Metric'
            >Metric</ToggleButton>
            <ToggleButton
              value='Natural'
            >Natural</ToggleButton>
          </ToggleButtons>

        </section>

        <section>
          <h1 className="header headline --large">Menu Dropdown</h1>
          <MenuDropdown
            buttonText="Select Me"
            listItems={menuListItems}
          />
        </section>

        <section>
          <h1 className="header headline --large">Autofill Dropdown</h1>
          <AutofillDropdown
            id="autofill-dropdown-menu"
            label="Form Label"
            options={
              [
                { label: '1234 Johnson Ferry Rd', sublabel: 'Atlanta, GA' },
                { label: '2160 Hills Ave', sublabel: 'Atlanta, GA' },
                { label: '5325 Glenridge Dr', sublabel: 'Atlanta, GA' },
                { label: '2332 Peachtree St NE', sublabel: 'Atlanta, GA' }
              ]
            } />
        </section>

        <section>
          <h1 className="header headline --large">Chips</h1>
          <h2 className="header headline --medium">Deletable</h2>
          <DeletableChipsList initialState={ deletableChipsItems } />
          <h2 className="header headline --medium">Clickable</h2>
          <SuccessChipsList initialState={ clickableChipsItems } />
        </section>

        <section>
          <h1 className="header headline --large">
            Article List Item
          </h1>
          <ArticleListItem
            subheader="Category"
            content="Descriptive Text Example Parallel's goodblend Texas Launches the First CBN Cannabis Product Line for Patients"
            title="Headline Sample With Lines"
            head="Headline Sample With Lines"
            fixedDate="Nov 11, 2021  -  7 min read"
          />
        </section>

        <SnackbarSection />

        <section>
          <div className='pagination-group'>
            <h1 className='header headline --large'>Pagination</h1>
            <div className="basic">
              <BasicPagination count={16} onChange={handleEvent} />
            </div>
            <div className='bullets'>
              <BulletPagination count={4} onChange={handleEvent} />
            </div>
            <div className='arrows'>
              <ArrowPagination count={16} onChange={handleEvent} />
            </div>
          </div>
        </section>

        <section className="--fullwidth">
          <h1 className="header headline --large">Job List Item</h1>

          <JobListItem
            description="Forward Air is seeking capable and career-oriented individuals to work in our fast-paced shipping/dock operation. Our Cargo Handlers are responsible for loading and unloading freight on our trailers in a safe and timely manner. Frequent lifting, pulling, pushing, and carrying of freight is required. All necessary training and materials for this position are provided."
            employer="Forward Air"
            title="Job Title Line"
            id="00129"
            city="Atlanta, GA, United States"
            postedAt="Jan. 11, 2022"
            responsibilities={
              [ "Responsible for the loading/unloading of 53 ft. commercial trailers and the operation of scanners",
                "Use handheld scanners to efficiently track and maneuver freight through our distribution network",
                "Accurately label boxes and prepare freight for shipment",
                "Safely load and unload trailers by utilizing equipment such as hand trucks/pallets jack and forklifts",
                "Must be able to obtain certification and safely operate forklift and other machinery as required",
                "Verify documentation and condition of freight to assure quality standards are achieved",
                "Must be able to frequently lift and/or move up to fifty (50) pounds",
                "Must be able to occasionally lift and/or move up to ninety (90) pounds",
                "Maintain a clean and safe working environment",
                "Other duties as assigned" ]
            }
            requirements={
              [
                "Excellent communication and problem solving skills",
                "Strong team player that thrives in a fast-paced environment",
                "Must be flexible to work weekends and overtime as needed",
                "Forklift and general freight handling knowledge is a plus",
                "Must be 18 or older" ]
            }
            summaryData={
              [
                {
                  label: 'Job Type',
                  value: 'Extra Extra Full Time'
                },
                {
                  label: 'Freight Type',
                  value: 'Line Over-The-Road'
                },
                {
                  label: 'Experience',
                  value: 'ALL CDL'
                },
                {
                  label: 'Location/City',
                  value: 'National'
                }
              ] // eslint-disable-line react/jsx-indent
            }
          >
          </JobListItem>
        </section>

        <section className="--fullwidth">
          <h1 className='header headline --large'>Simple Cards</h1>
          <div className="card-group">
            <Card
              avImage="avatar"
              heading="First Last Name"
              subheading="Title"
              className = "xtra-small"
            />
            <Card
              topIcon = {<IconBank color="#c8102e" />}
              heading ="Medical, Dental, and Vision Coverage"
              className = "small"
            />

            <Card
              heading ="Medical, Dental, and Vision Coverage"
              className="medium"
              ctaButton={
                <Button
                  variant="text"
                  className="no-padding"
                  startIcon={<IconLinkArrow/>}
                  disableRipple
                >
                  Learn More
                </Button>
              }
            />

            <Card
              heading ="Carrier Manager"
              className="large"
              ctaButton={
                <Button
                  variant="text"
                  className="no-padding"
                  startIcon={<IconLinkArrow/>}
                  disableRipple
                >
                  Learn More
                </Button>
              }
            >
              <p>Space is the ultimate fundamental;
                the most ubiquitous “thing” there is.
                In fact our brains generally perceive space as the “nothing”
                between the “things” they prefer to fixate on and rarely give it thought.
              </p>
            </Card>
          </div>
        </section>

        <section className="--fullwidth">
          <h1 className="header headline --large flex-row">Content Card</h1>
          <ContentCardSection />
        </section>

        <section>
          <h1 className="header headline --large flex-row">Half-column content</h1>
          <HalfColumnContent
            category="Positions"
            header="Driving Opportunities"
            copy="A career in trucking is no different than other careers. It provides opportunities for growth and advancement as a professional. If you are looking for change, Forward can help take your trucking career to the next level."
            ctaRow={
              <>
                <Button variant="contained">Apply</Button>
                <Button variant="text">Fast Apply</Button>
              </>
            }
            callOut="Contact a recruiter if you have any questions."
            callOutLink="/"
          />
        </section>

        <AlertBarSection />

        <section>
          <h1 className="header headline --large flex-row">New To Forward</h1>
          <NewToForward />
        </section>

        <section>
          <h1 className="header headline --large flex-row">Accordion</h1>
          <AccordionSection />
        </section>
        <ModalSection />

        <NewToForwardSection />

        <section>
          <SliderSection/>
        </section>

        <section>
          <h1 className="header headline --large flex-row">Tooltip</h1>
          <div className='tool-tips'>
            <div className='top-tips'>
              <FwrdTooltip
                title="Tip"
                placement="top-start">
                <button>Top Start</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="Tip"
                placement="top">
                <button>Top</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="Tip"
                placement="top-end">
                <button>Top End</button>
              </FwrdTooltip>
            </div>
            <div className='tips'>
              <FwrdTooltip
                title="Tip"
                placement="left">
                <button>Left</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="This is some tooltip text. This box shows the maximum amount of text that should be displayed inside. If more room is needed, use a modal instead."
                placement="top">
                <button>Definition</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="Tip"
                placement="right">
                <button>Right</button>
              </FwrdTooltip>
            </div>
            <div className='bottom-tips'>
              <FwrdTooltip
                title="Tip"
                placement="bottom-start">
                <button>Bottom Start</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="Tip"
                placement="bottom">
                <button>Bottom</button>
              </FwrdTooltip>
              <FwrdTooltip
                title="Tip"
                placement="bottom-end">
                <button>Bottom End</button>
              </FwrdTooltip>
            </div>
          </div>

        </section>

        <InputFieldWithChipSection/>

        <ContactModalSection />

        <section>
          <h1 className="header headline --large">Date Picker</h1>
          <FwrdDatePicker
            label='date'
            placeholder='date'
          />
        </section>

        <section>
          <h1 className="header headline --large">Upload Example</h1>
          <UploadExample />
        </section>
      </div>
    </>
  );
}
